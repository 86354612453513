import store from './store/index';
import AuthService from './service/AuthService'

export async function checkAuthentication(to) {
  const requiresAuth = to.meta.requiresAuth
  const accessToken = store.getters['auth/access_token']
  const refreshToken = store.getters['auth/refresh_token']
  switch (true) {
    case requiresAuth && !accessToken:
      throw new Error('No se ha iniciado sesión')
    case !requiresAuth && refreshToken:
      await AuthService.refresh_access(refreshToken)
      break
    default:
      break
  }
}