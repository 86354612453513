<template id="calendarActivity">
    <div >
        <h4 class="text-black">Gestion por Tipo Documentos</h4>
        <div class="card ml-3 mr-3 p-grid p-nogutter p-d-flex p-justify-center p-ai-cente">
                <div class="field">
                    <label for="contractdropdown" class="p-sr-only">Contrato</label>
                    <div class="field">
                        <Dropdown id="contractdropdown" v-model="selectedContract" :options="contracts" optionLabel="Name" :filter="false" placeholder="Todos los Contratos" :showClear="true">
                          <template #value="slotProps">
                              <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                  <span>
                                    {{slotProps.value.name}}
                                  </span>
                              </div>
                              <span v-else>
                                  {{slotProps.placeholder}}
                              </span>
                          </template>
                          <template #option="slotProps">
                          <div class="p-dropdown-supervisors-option">
                            <span>
                                  {{slotProps.option.name}}</span>
                          </div>
                        </template>
                        </Dropdown>
                        <buttonp class="w-8rem p-button-warning ml-2 " icon="pi pi-search" label="BUSCAR" @click.prevent="pressSearch()"/>
                         <!--h4 class="text-black">..Cargando..</h4-->
                        <ProgressBar class="mt-2" v-if="startLoadingReport" mode="indeterminate" style="height: 0.5px;"/>
                    </div>
                </div>
 
        </div>
        
        <div v-if="resultReport.length > 0" >
            <div class="card ml-6 mr-6 mt-3">
                <h4 class="text-black">TOTALES</h4>
                <h5 class="text-black">{{resultReport.length}} Tipos Documentos evaluados.</h5>
                <!--buttonp class="w-8rem p-button-warning mb-2" icon="pi pi-file-excel" label="Exportar Excel" @click.prevent="exportExcel()"/-->
            </div>
            <DataTable id="tableDocumentatio" :value="resultReport" responsiveLayout="scroll">
                <Column field="name" header="Tipo Documento" :sortable="true"></Column>
                <Column header="Colaborador Evaluados" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users, 'Evaluados', slotProps.index)" autofocus disabled/>
                    </template>
                </Column>

                <Column field="correct" header="#CORRECTAS" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_correct.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_correct, 'Correctas', slotProps.index)" autofocus />
                    </template>
                </Column>
                <Column field="alert" header="#ALERTA" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_alert.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_alert, 'Alerta', slotProps.index)" autofocus />
                    </template>
                </Column>
                <Column field="process" header="#PROCESO" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_process.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_process, 'Proceso', slotProps.index)" autofocus />
                    </template>
                </Column>
                <Column field="critic" header="#CRITICOS" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_critic.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_critic, 'Criticos', slotProps.index)" autofocus />
                    </template>
                </Column>
                <Column field="check" header="#EXPIRADOS" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_expired.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_expired, 'Expirado', slotProps.index)" autofocus />
                    </template>
                </Column>
                <Column field="check" header="#POR REVISAR" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_check.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_check, 'Revisar', slotProps.index)" autofocus />
                    </template>
                </Column>
                <!--Column field="rejected" header="#RECHAZADOS" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_rejected.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_rejected,'Rechazados', slotProps.index)" autofocus />
                    </template>
                </Column-->
                <!--Column header="#RENOVADOS" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_renewed.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_renewed, 'Renovados', slotProps.index)" autofocus />
                    </template>
                </Column-->
            </DataTable>
        </div>
        <div v-else>
            <h2 v-if="!startLoadingReport">Sin Resultados</h2>
            <h2 v-else>...Cargando...</h2>
        </div>


        <br /><br /><br /><br />
        <Dialog :header="selected_documentation + '(' + selectedDetails.length.toString() + ')'" v-model:visible="displayDetailDocs" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '80vw'}">

            <DataTable id="tableDetailCOlaborador" :value="selectedDetails" responsiveLayout="scroll">
                <Column header="Colaborador" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.employee}}
                            <!--buttonp :label="slotProps.data.employee" @click="openAdminColaborator(slotProps.data.e, slotProps.data.doc.documentation_implementation_id)" autofocus /-->
                    </template>
                </Column>
                <!--Column header="ID DOCUMENTO" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.doc.documentation_implementation_id}}
                </template>
                </Column-->
                <Column header="Expiracion" :sortable="true">
                    <template #body="slotProps">
                        <buttonp v-if="slotProps.data.doc.calculate_status == 'ALERT'" :label="getLocalDate(slotProps.data.doc.expiration).toString()" class="ml-1 p-button-sm p-button-warning" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO EN ALERTA'" autofocus/>
                        <buttonp v-if="slotProps.data.doc.calculate_status == 'CRITIC'"  :label="getLocalDate(slotProps.data.doc.expiration).toString()" class="ml-1 p-button-sm p-button-danger" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO CRITICO'" autofocus disabled/>
                        <buttonp v-if="slotProps.data.doc.calculate_status == 'EXPIRED'"  :label="getLocalDate(slotProps.data.doc.expiration).toString()" class="ml-1 p-button-sm p-button-danger" icon="pi pi-times" v-tooltip.left="'DOCUMENTO EXPIRADO'" autofocus/>
                        <buttonp v-if="slotProps.data.doc.calculate_status == 'OK'"  :label="getLocalDate(slotProps.data.doc.expiration).toString()" class="ml-1 p-button-sm p-button-success" icon="pi pi-check" v-tooltip.left="'DOCUMENTO NO EXPIRADO'" autofocus/>
                    </template>
                </Column>
                <Column header="ESTADO" :sortable="true">
                    <template #body="slotProps">
                        <statustag :status="slotProps.data.doc.status"></statustag>
                    </template>
                </Column>
                <!--Column field="limit_renew" header="Limite C.Renovacion" :sortable="true">
                    <template #body="slotProps">
                        {{getLocalDate(slotProps.data.doc.limit_renew)}}
                    </template>
                </Column-->
                <!--Column field="start_renew" header="Comenzo Renovacion" :sortable="true"></Column-->
                <!--Column field="renew" header="Fecha Renovacion" :sortable="true"></Column-->

                <Column header="Acciones" style="width: 200px;">
                    <template #body="slotProps">

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-book" v-tooltip.left="'Ver Observaciones'" @click="openObservationModal(slotProps.data.doc)" autofocus/>

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-pencil" v-tooltip.left="'Editar Documento'" @click="openEditDocumentation(slotProps.data.doc.documentation_implementation_id)" autofocus :disabled="slotProps.data.doc.status === 'STARTRENEW' || slotProps.data.doc.status === 'REJECTED' || slotProps.data.doc.status === 'REJECT' || slotProps.data.doc.status === 'RENEW'" />

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-history" v-tooltip.left="'Iniciar Renovacion Documento'" @click="startRenewProcess(slotProps.data.doc.documentation_implementation_id)" autofocus :disabled="slotProps.data.doc.status === 'STARTRENEW' || slotProps.data.doc.status === 'REJECTED' || slotProps.data.doc.status === 'REJECT' || slotProps.data.doc.status === 'RENEW'"/>

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-sign-out" v-tooltip.left="'Finalizar Renovacion'" @click="finishRenew(slotProps.data.doc.documentation_implementation_id, slotProps.data.doc.documentation_id, slotProps.data.doc.asigned_id)" autofocus :disabled="slotProps.data.doc.status === 'REJECTED' || slotProps.data.doc.status === 'REJECT' || slotProps.data.doc.status === 'RENEW'"/>

                        <!--buttonp label="" class="ml-1 p-button-sm" icon="pi pi-exclamation-circle" v-tooltip.left="'Rechazar Documento'" @click="rejectedDocument(slotProps.data.documentation_implementation_id)" autofocus /-->
                    

                        <!--buttonp label="" class="ml-1 is-red p-button-sm" icon="pi pi-trash" v-tooltip.left="'Eliminar Documento'" @click="deleteDocumentation(slotProps.data.documentation_implementation_id)" autofocus /-->

                    </template>
                </Column>

            </DataTable>

            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-check" @click="closeDetail" autofocus />
            </template>
        </Dialog>

        <Dialog :header="'Editar Documentation'" v-model:visible="displayEditDoc" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 100vh">
                <editdocucomponent :docu_im_id="selectedIdDocumentation" v-on:ok-edit="refreshData"></editdocucomponent>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displayEditDoc = false;" autofocus />
            </template>
        </Dialog>

        <Dialog :header="'Comienza Proceso de Renovacion'" v-model:visible="displaystartRenewProcess" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 100vh">
                <startrenewdocu :id_docu_impl="selectedIdDocumentation" v-on:ok-edit="refreshData"></startrenewdocu>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displaystartRenewProcess = false;" autofocus />
            </template>
        </Dialog>

        <Dialog :header="'Finalizar Renovacion Documentacion'" v-model:visible="displayfinishRenew" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 100vh">
                <renewdocu :id_docu_impl="selectedIdDocumentation" :id_docu="selectedDocuType" :employee_id="selectedAsigned" v-on:ok-edit="refreshDataRenew"></renewdocu>

            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displayfinishRenew = false;" autofocus />
            </template>
        </Dialog>

        <Dialog :header="'Observaciones Documentation'" v-model:visible="displayObsDoc" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 100vh">
                <obsdocucomponent :docu="selectedObservations"></obsdocucomponent>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displayObsDoc = false;" autofocus />
            </template>
        </Dialog>
        
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';
    import ContractService from '@/service/ContractService';
    import Statustag from '@/components/StatusTag';
    import Editdocucomponent from '@/components/EditDocuComponent';
    //import Deletedocu from '@/components/DeleteDocuComponent'
    //import Rejectdocu from '@/components/RejectDocuComponent'
    import Startrenewdocu from '@/components/StartRenewDocuComponent'
    import Renewdocu from '@/components/RenewDocuComponent'
    import moment from "moment";
    import Obsdocucomponent from '@/components/Obsdocucomponent'


    let AdminDocuType = {};
    (function () {
        this.name = 'admin-docu-type-view';

        this.created = function () {
            this.getAllContractUser();
            this.getAllDocumentation();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                contracts: [],
                selectedContract: null,
                resultReport: [],
                selectedDetails: [],
                documentations: [],
                displayDetailDocs: false,
                selected_documentation: null,
                selectedIdDocumentation: null,
                displayEditDoc: null,
                displaystartRenewProcess: null,
                displayfinishRenew: null,
                selectedDocuType: null,
                selectedIdDocuAfterRenew: null,
                column_status_users: null,
                index_docy_type: null,
                startLoadingReport: false,
                displayObsDoc: false,
                selectedObservations: null,
            }
        };
        this.methods = {
            openObservationModal(doc){
                this.displayObsDoc = true;
                this.selectedObservations = doc;
            },
            pressSearch(){
                this.selectedIdDocuAfterRenew = null;
                this.column_status_users = null;
                this.index_docy_type = null;
                this.getAllReport();
            },
            refreshDataRenew(value){
                this.selectedIdDocuAfterRenew = value;
                this.displayEditDoc = false;
                this.displaystartRenewProcess = false;
                this.displayfinishRenew = false;
                //this.displayrejectedDocument = false;
                //this.displaydeleteDocumentation =  false;
                //this.displayDetailUser = false;
                this.displayDetailDocs = false;
                this.getAllReport();
            },
            refreshData(){
                this.displayEditDoc = false;
                this.displaystartRenewProcess = false;
                this.displayfinishRenew = false;
                //this.displayrejectedDocument = false;
                //this.displaydeleteDocumentation =  false;
                //this.displayDetailUser = false;
                this.displayDetailDocs = false;
                this.getAllReport();
            },
            //edit
            openDetailColaborator(docu, details, column_status_users, index_docy_type){
                this.selected_documentation = docu;
                this.displayDetailDocs = true;
                this.selectedDetails = []
                this.selectedDetails = details;
                this.column_status_users = column_status_users;
                this.index_docy_type = index_docy_type;

            },
            openEditDocumentation(id){
                this.displayEditDoc=true;
                this.selectedIdDocumentation = id;
                //this.$store.dispatch('employee/set-employee', this.selected_colaborator);
                //this.$router.push({name: 'admin-documentation', params: { docu_id: id } });
            },
            startRenewProcess(id){
                this.displaystartRenewProcess = true;
                this.selectedIdDocumentation = id;
            },
            finishRenew(id_imple, documentation_id, asigned_id){
                this.displayfinishRenew = true;
                this.selectedIdDocumentation = id_imple;
                this.selectedDocuType = documentation_id;
                this.selectedAsigned = asigned_id;
            },
            openAdminColaborator(e, id){
                e.first_name1 = e.first_name1 +" "+e.first_name2 +' '+e.last_name1 + ' ' +e.last_name2 +'('+e.card_id +'-'+ e.check_digit +')';

                this.$store.dispatch('employee/set-employee', e);


                //let routeData = this.$router.resolve({name: 'admin-documentation', query: {data: "someData"}});
                //window.open(routeData.href, '_blank');

                this.$router.push({name: 'admin-documentation', 
                                    params: {
                                        docu_id: id
                                        }
                                    });
            },
            getDocumentationById(id){
               return  this.documentations.find(item => item.documentation_id === id).name;
            },
            getAllDocumentation(){
                this.$store.dispatch('form/submit', true);
               DocumentationService.allDocumentation().then((response) => {
                    this.documentations = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            exportExcel(){

            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY');
                  return local;
                }
            },
            closeDetail(){
                this.displayDetailDocs = false;
            },
            getAllContractUser(){
                ContractService.getByEmployee(this.username).then((response) => {
                    this.contracts = response.data;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAllReport() {
                this.startLoadingReport = true;
                this.resultReport = []
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}
                var contractsid = null
                if(this.selectedContract != null)
                    contractsid = this.selectedContract.contract_id
                DocumentationService.docuTypeReport(contractsid)
                    .then(response => {
                         this.resultReport = response.data;
                    if(response.data.length === 0){
                        result.isError = true;
                        result.message = 'Sin resultados';
                        this.$store.dispatch('form/result', result);
                    }
    
                    if(this.index_docy_type != null && this.column_status_users != null){
                        this.selected_documentation =  this.resultReport[this.index_docy_type].name;
                        this.selectedDetails = []
                        if(this.selectedIdDocuAfterRenew !== null){
                            this.selectedDetails =  this.resultReport[this.index_docy_type].users_correct;
                            this.selectedIdDocumentation = this.selectedIdDocuAfterRenew;
                            this.selectedIdDocuAfterRenew = null;
                            this.displayDetailDocs =true;
                            this.displayEditDoc = true;
                        }else{
                            if(this.column_status_users === 'Evaluados'){
                                this.selectedDetails =  this.resultReport[this.index_docy_type].users;
                            }

                            if(this.column_status_users === 'Correctas'){
                                 this.selectedDetails =  this.resultReport[this.index_docy_type].users_correct;
                            }

                            if(this.column_status_users === 'Alerta'){
                                this.selectedDetails =  this.resultReport[this.index_docy_type].users_alert;
                            }

                            if(this.column_status_users === 'Proceso'){
                                this.selectedDetails =  this.resultReport[this.index_docy_type].users_process;
                            }

                            if(this.column_status_users === 'Criticos'){
                                this.selectedDetails =  this.resultReport[this.index_docy_type].users_critic;
                            }

                            if(this.column_status_users === 'Expirados'){
                                this.selectedDetails =  this.resultReport[this.index_docy_type].users_expired;
                            }

                            if(this.column_status_users === 'Revisar'){
                                this.selectedDetails =  this.resultReport[this.index_docy_type].users_check;
                            }

                            if(this.column_status_users === 'Rechazados'){
                                this.selectedDetails =  this.resultReport[this.index_docy_type].users_rejected;
                            }

                            this.displayDetailDocs = true;
                        }

                    }

                    this.startLoadingReport = false;
                    this.$store.dispatch('form/submit', false);
                    })
                    .catch(err => {
                      console.log(err);
                      this.startLoadingReport = false;
                       this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.components = {Statustag, Editdocucomponent, Startrenewdocu, Renewdocu, Obsdocucomponent};
        this.computed = {
            ...mapGetters({
                username: 'auth/user'
            }),
        };
        this.watch = {

        };
        this.props = [];
    }).apply(AdminDocuType);

    export default AdminDocuType
</script>
