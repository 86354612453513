<template id="startprocessrenewvew">
        <startrenewdocu :id_docu_impl="selectedIdDocumentation" v-on:ok-edit="goAdmin"></startrenewdocu> 
</template>


<script>
    import {mapGetters} from 'vuex';
    import Startrenewdocu from '@/components/StartRenewDocuComponent'

    let StartProcessRenew = {};
    (function () {
        this.name = 'startprocess-documentation-view';
        // let _self = null;

        this.created = function () {
            this.selectedIdDocumentation = this.$route.params.documentation_implementation_id;
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                selectedIdDocumentation: null,
            }
        };
        this.methods = {
            goAdmin(){
                var goin = 'admin-documentation';
                if(this.$route.params.for == 'vehicle'){
                    goin = 'admin-documentation-vehicle';
                }
                this.$router.push({name: goin, params: { docu_id: this.selectedIdDocumentation } }); 
            }
        };
        this.components = {Startrenewdocu

        };
        this.computed = {
            ...mapGetters({

            }),
        };
        this.watch = {
            
        };
        this.props = [];
    }).apply(StartProcessRenew);

    export default StartProcessRenew
</script>
