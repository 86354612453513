const state = () => ({
	vehicle: localStorage.getItem('vehicle'),
});
 
const getters = {
	vehicle(state) {
        return state.vehicle
    },
};
 
const actions = {
	'set-vehicle'(context, vehicle) {
        context.commit('vehicle', vehicle);
        localStorage.setItem('vehicle', vehicle);
    },
};
 
const mutations = {
	vehicle(state, value) {
		state.vehicle = value
	},
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}