import axios from "axios";

export default {

	getExcelStatus() {
        const url = `${process.env.VUE_APP_API_CORE_URL}/employees`;
        let data = {operation: 'get-excel-status'}
        return axios.post(url, data)
    },

	colaboratorStatus(id) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/employees`;
        let data = {operation: 'get-collaborator-status',user_id: id}
        return axios.post(url, data)
    },

    getAll() {
        const url = `${process.env.VUE_APP_API_CORE_URL}/employees`;
        return axios.get(url)
    }


}
