<template id="home">
    <div class="card p-fluid">

<h5 class="text-black">Consulta Vehiculos</h5>

    <div class="grid">
        <div class="col-12 md:col-6 lg:col-4">
            <buttonp class="w-6 mt-3 p-button-warning" label="Por Vehiculo" @click.prevent="goTo('vehicle-report')"/>
        </div>

        <div class="col-12 md:col-6 lg:col-4">
            <buttonp class="w-6 mt-3 p-button-warning" label="Por Estado Documento" @click.prevent="goTo('all-vehicle-report')"/>
        </div>
        <div class="col-12 md:col-6 lg:col-4">
            <buttonp class="w-6 mt-3 p-button-warning" label="Por Tipo Documento" @click.prevent="goTo('vehicle-docu-type-report')"/>
        </div>
    </div>

 <h5 class="text-black">Gestion Documentacion Vehiculo</h5>
  <div class="grid">
    <div class="col-12 md:col-6 lg:col-4">
        <buttonp class="w-6 mt-3 p-button-warning" label="Gestion General" @click.prevent="goAdminDocu()"/>
    </div>
    <div class="col-12 md:col-6 lg:col-4">
        <buttonp class="w-6 mt-3 p-button-warning" label="Gestion por Estado Documento" @click.prevent="goAdminDocuDivi()"/>
    </div>
    <!--div class="col-12 md:col-6 lg:col-4">
        <buttonp class="w-6 mt-3 p-button-warning" label="Gestion por Tipo Documento" @click.prevent="goAdminDocuType()"/>
    </div-->
    <div class="col-12 md:col-6 lg:col-4">
        <buttonp class="w-6 mt-3 p-button-warning" label="Ingreso Nueva Documentacion Vehiculo" @click.prevent="goTo('new-documentation-vehicle')"/>
    </div>
    <div class="col-12 md:col-6 lg:col-4">
        <buttonp class="w-6 mt-3 p-button-warning" label="Crear Nuevo Vehiculo" @click.prevent="goTo('create-all-docu-vehicle')"/>
    </div>
</div>

        
        <!--buttonp class="w-6 mt-3 p-button-warning" label="Mi Documentacion" @click.prevent="goTo('my-documentation')"/-->

        <br /><br /><br /><br /><br /><br /><br />
        <!--buttonp class="w-6 mt-3 p-button-warning" label="Iniciar Proceso Renovacion" @click.prevent="goTo('start-process-renew')"/-->

        <!--buttonp class="w-6 mt-3 p-button-warning" label="Terminar Renovacion" @click.prevent="goTo('finish-renew')"/-->

    </div>
</template>


<script>
    

    import {mapGetters} from 'vuex';
    


    let HomeVehicle = {};
    (function () {
        this.name = 'home-vehicle';
        // let _self = null;

        this.created = function () {

        };

        this.mounted  = function () {
            window.scrollTo(0, 0);
        };

        this.data = function () {
            return {
               
            }
        };
        this.methods = {
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            goAdminDocuType(){
                this.$router.push({name: 'admin-docu-type'});
            },
            goAdminDocuDivi(){
                this.$router.push({name: 'admin-all-vehicle-docu'});
            },
            goAdminDocu: function(){
                 this.$router.push({name: 'admin-documentation-vehicle', 
                    params: {
                        docu_id: 0
                        }
                    });
            },
            goTo: function (route) {
                this.$router.push({name: route});
            },
        };
        this.computed = {
             ...mapGetters({
                groups: 'auth/groups'
            })
        };
        this.watch = {

        };
        

        this.props = [];
    }).apply(HomeVehicle);

    export default HomeVehicle
</script>
