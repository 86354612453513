import axios from "axios";

export default {
    getAll() {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles`;
        return axios.get(url);
    },
    get(id) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicle`;
        return axios.get(url, id)
    },

    get_by_id(id) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicle`;
        let data = {vehicle_id: id}
        return axios.post(url, data)
    },

    guardarVehiculo(vehicle) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/newvehicle`;
        return axios.post(url, vehicle)
    },

    useVehicle(vehicle, kilometers, tray) {
        const url = `${process.env.VUE_APP_API_CORE_URL}/vehicles`;
        let data = {operation: 'use-vehicle', vehicle: vehicle, kilometers: kilometers, tray: tray}
        return axios.post(url, data)
    },
}
