<template id="redirect_admin-docu">
    <div class="columns">
        <div class="column is-12 has-text-centered is-centered">
            <img  alt="logo corporativo" src="@/assets/logo.svg" style="width:10rem;" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "redirect-admin-docu-vehicle",
        data: () => ({

        }),
        created() {         
            this.goTo('admin-documentation-vehicle', 0);
        },
        mounted() {
            window.scrollTo(0, 0);
        },
        methods: {
            goTo: function (route, id) {
               this.$router.push({name: route, params: { docu_id: id } });
            }
        },
        computed:{

        }
    }
</script>

