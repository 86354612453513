<template id="doActivity">
    <span>
        <div class="md-min-width card m-5" v-if="docu !== null">

                <h5 class="text-black">Observación general</h5>
                <Textarea v-model="docu.observation" rows="5" cols="30" disabled/>
               
                    <h5 class="text-black">Observacion comienzo renovacion</h5>
                    <Textarea v-model="docu.observation_start_renew" rows="5" cols="30" disabled/>

                    <h5 class="text-black">Observacion renovacion</h5>
                    <Textarea v-model="docu.observation_renew" rows="5" cols="30" disabled/>

                    <h5 class="text-black">Observacion Rechazo</h5>
                    <Textarea v-model="docu.observation_reject" rows="5" cols="30" disabled />
                
                <br/><br/>
                <br/><br/>
                <br/><br/>

        </div>
    </span>
</template>


<script>
    import {mapGetters} from 'vuex';

    let Obsdocucomponent = {};
    (function () {
        this.name = 'obs-documentation-compo';
        // let _self = null;

        this.created = function () {

            
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {

            }
        };
        this.methods = {
            
        };
        this.computed = {
           
            ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states'
            }),
        };
        this.watch = {
            
        };
        this.props = ['docu'];
    }).apply(Obsdocucomponent);

    export default Obsdocucomponent
</script>
