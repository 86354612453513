<template id="calendarActivity">
    <div >
        <h3 class="text-black">Detalle Personal  <buttonp class="p-button-warning ml-2 " icon="pi pi-note" :label="'Exportar Excel'" @click.prevent="exportExcel()"/></h3>

        <div  >

            <buttonp class="w-10rem p-button-warning ml-2 " icon="pi pi-search" :label="'Activos('+ num_actives +')'" @click.prevent="selectActivos()"/>

            <buttonp class="w-10rem p-button-warning ml-2 " icon="pi pi-search" :label="'Licencia('+ num_license +')'" @click.prevent="selectConLicencia()"/>

            <buttonp class="w-10rem p-button-warning ml-2 " icon="pi pi-search" :label="'Inactivos('+ num_inactives +')'" @click.prevent="selectInactivos()"/>

            <buttonp class="w-12rem p-button-warning ml-2 " icon="pi pi-search" :label="'Indetermindado('+ num_indetermiando +')'" @click.prevent="selectIndeterminado()"/>

              <div v-if="selectedColaboratos.length > 0" >
                <DataTable id="tableDetailCOlaborador" :value="selectedColaboratos" responsiveLayout="scroll" class="mt-3">
                    <Column header="ID" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.employee_id}}
                        </template>
                    </Column>
                    <Column header="Rut" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.card_id}}-{{slotProps.data.check_digit}}
                        </template>
                    </Column>
                    <Column header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.first_name1}} {{slotProps.data.first_name2}} {{slotProps.data.last_name1}} {{slotProps.data.last_name2}}
                        </template>
                    </Column>
                    <Column header="Cumpleaños" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.birthday}}
                        </template>
                    </Column>
                    <Column header="Comienzo Contrato" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.contract_start}}
                        </template>
                    </Column>
                </DataTable>
            </div>
            <div v-else>
                <h2>Sin Resultados</h2>
            </div>
        </div>
        <br /><br /><br /><br />


        <Dialog :header="'Reporte Estado de Colaboradores'" v-model:visible="openModalExcel" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 20%; min-height: 20vh">

                    <p class="modal-card-title" v-if="link_excel !== null">Reporte creado</p>
                    <p class="has-text-dark" v-if="link_excel === null">¿Desea crear un nuevo reporte?</p>

                
                    <buttonp v-if="link_excel !== null"  class="button is-warning" @click.prevent="openFile()">Bajar Archivo</buttonp>
                                 

                    <buttonp  v-if="link_excel === null" class="button is-warning" @click.prevent="descargarExcel()">Obtener Archivo</buttonp>

                        
            </div>
             <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="openModalExcel = false; link_excel = null;" autofocus />
            </template>
        </Dialog>


    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    //import DocumentationService from '@/service/DocumentationService';
    import EmployeeService from '@/service/EmployeeService';
    import moment from "moment";


    let MyResult = {};
    (function () {
        this.name = 'my-documentation';

        this.created = function () {
            this.getStatus();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                selectedColaboratos: [],
                all_data_colla:null,
                num_actives: 0,
                num_inactives: 0,
                num_license: 0,
                num_indetermiando: 0,
                openModalExcel: false,
                link_excel:null,
            }
        };
        this.methods = {
            openFile(){
                console.log(this.link_excel);
                window.open(this.link_excel, '_blank');
              },
            exportExcel(){
                this.openModalExcel = true;
            },
            descargarExcel(){
                this.$store.dispatch('form/submit', true);
                EmployeeService.getExcelStatus().then((response) => {
                    this.link_excel = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            selectConLicencia(){
                this.selectedColaboratos = this.all_data_colla.license;
            },
            selectActivos(){
                this.selectedColaboratos = this.all_data_colla.actives;
            },
            selectIndeterminado(){
                this.selectedColaboratos = this.all_data_colla.indeterminado;
            },
            selectInactivos(){
                this.selectedColaboratos = this.all_data_colla.inactives;
            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY');
                  return local;
                }
            },
            getStatus() {
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}

                EmployeeService.colaboratorStatus(this.username)
                    .then(response => {
                            this.all_data_colla = response.data;
                         this.selectedColaboratos = response.data.actives;
                         this.num_actives= response.data.actives.length;
                        this.num_inactives=  response.data.inactives.length;
                        this.num_license=  response.data.license.length;
                        this.num_indetermiandov=  response.data.indeterminado.length;
                    if(response.data.length === 0){
                        result.isError = true;
                        result.message = 'No se encontraron colaboradores registrados';
                        this.$store.dispatch('form/result', result);
                    }

                    this.$store.dispatch('form/submit', false);
                    })
                    .catch(err => {
                      console.log(err);
                       this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.computed = {isOpenModalExcel(){return this.openModalExcel},
            ...mapGetters({
                username: 'auth/user'
            }),
        };
        this.watch = {

        };
        this.props = [];
    }).apply(MyResult);

    export default MyResult
</script>
