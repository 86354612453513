<template id="doActivity">
    <span>
        <div class="md-min-width card m-5">
                <h2 class="text-black">Nuevo Documento</h2>


                <h5 class="text-black">Trabajador asignado al documento</h5>
                <div class="field">
                    <autoComplete v-model="selectedAsignedEmployee" field="first_name1" :suggestions="filteredEmployee" @complete="search($event)" placeholder="Seleccione Persona"></autoComplete>
                    <h6 v-if="error.asigned_oblig" class="p-error">Seleccion dueño de la documentacion.</h6>
                </div>
                
                <div class="field">
                    <h5 class="text-black">Tipo Documento</h5>
                    <Dropdown id="dropDocumentation" v-model="selectedDocumentation" :options="documentations" optionLabel="Nombre" :filter="false" placeholder="Seleccione Documentacion" :showClear="true">
                      <template #value="slotProps">
                          <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                              <span>
                                {{slotProps.value.name}}
                              </span>
                          </div>
                          <span v-else>
                              {{slotProps.placeholder}}
                          </span>
                      </template>
                      <template #option="slotProps">
                      <div class="p-dropdown-supervisors-option">
                        <span>
                              {{slotProps.option.name}}</span>
                      </div>
                    </template>
                    </Dropdown>
                    <h6 v-if="error.act_oblig" class="p-error">Seleccion documentacion obligatoria.</h6>
                </div>


                <!--h5 class="text-black">Expiracion</h5>
                <Calendar id="expiredIdCalendar" v-model="docu_implementation.expiration" :showTime="false" /-->


                
                <span v-if="selectedDocumentation !== null">
                    <h5 class="text-black">Expiracion</h5>
                    <span>
                        <Checkbox id="binary" v-model="inf_contract" :binary="true" />
                        <label for="binary" class="text-black">Contrato indefinido</label>
                    </span>
                    <br />
                    <br />
                    <Calendar v-if="inf_contract !== true"  id="expiredIdCalendar" v-model="docu_implementation.expiration" :showTime="false" dateFormat="dd/mm/yy" />
                </span>
                

                <!--h5 class="text-black">Limite de renovacion(Opcional)</h5>
                <Calendar id="expiredLimite" v-model="docu_implementation.limit_renew" :showTime="false" /-->

                <!--div class="field mt-4">
                    <FileUpload mode="basic" name="demo[]" url="./upload" chooseLabel="Ingrese Comprobante" :customUpload="true" @uploader="myUploader"/>
                </div-->

                <h5 class="text-black">Observacion ingreso</h5>
                <Textarea v-model="docu_implementation.observation" rows="5" cols="30" />

                <br/><br/>
                
                <buttonp class="w-8rem mt-5 p-button-warning" icon="pi pi-save" label="NUEVO" @click.prevent="newDocumentationImplementation()"/>

                <br/><br/>

                <br/><br/><br/><br/><br/><br/><br/><br/>
                
        </div>
    </span>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';

    import moment from "moment";

    let NewDocumentation = {};
    (function () {
        this.name = 'new-documentation';
        // let _self = null;

        this.created = function () {
            this.getAllEmployee();
            this.getAllDocumentation();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                inf_contract: false,
                allEmployees: [],
                selectedDocumentation: null,
                documentations: [],
                filteredEmployee: [],
                selectedAsignedEmployee: null,
                docu_implementation: {
                    documentation_id: null,
                    expiration: null,
                    limit_renew: null,
                    asigned_id: null,
                    observation: null,
                }
            }
        };
        this.methods = {
            myUploader(){

            },
            search(event){
                var filteredResult = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.allEmployees.length; i++) {
                        if (this.allEmployees[i].first_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) ||
                          this.allEmployees[i].last_name1.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResult.push(this.allEmployees[i])
                        }
                    }
                    this.filteredEmployee = filteredResult;
                } else {
                    this.filteredEmployee = [];
                }   
            },

            getAllEmployee(){
                this.$store.dispatch('form/submit', true);
                DocumentationService.getEmployeeByBusiness().then((response) => {
                    this.allEmployees = response.data;
                    this.filteredEmployee = response.data;
                    for (var i = 0; i < this.allEmployees.length; i++) {
                            this.allEmployees[i]['first_name1'] = this.allEmployees[i]['first_name1'] +" "+this.allEmployees[i]['first_name2']+' '+this.allEmployees[i]['last_name1'] + ' ' +this.allEmployees[i]['last_name2']+'('+this.allEmployees[i]['card_id'] +'-'+ this.allEmployees[i]['check_digit']+')';
                        }
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAllDocumentation(){
                this.$store.dispatch('form/submit', true);
               DocumentationService.allDocumentationPerson().then((response) => {
                    this.documentations = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            newDocumentationImplementation() {
                this.$store.dispatch('form/reset');
                this.$store.dispatch('form/submit', true)
                this.$store.dispatch('form/error', {
                    ocu_oblig: false,
                    asigned_oblig: false,
                });

                if (this.selectedDocumentation === null) {
                    //this.$store.dispatch('form/error', {act_oblig: true});
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Tipo documento'});
                    this.$store.dispatch('form/submit', false)
                    return

                }

                if (this.selectedAsignedEmployee === null) {
                    //this.$store.dispatch('form/error', {act_asigned_oblig: true});
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Colaborador'});
                    this.$store.dispatch('form/submit', false)
                    return
                }

                if (this.docu_implementation.expiration === null) {
                    //this.$store.dispatch('form/error', {act_asigned_oblig: true});
                    if(this.inf_contract === false){
                        this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Expiracion o indefinido'});
                        this.$store.dispatch('form/submit', false)
                        return
                    } 
                }

                if (this.docu_implementation.observation === null) {
                    //this.$store.dispatch('form/error', {act_asigned_oblig: true});
                    this.$store.dispatch('form/result', {isError: true, message: 'Ingrese observacion de ingreso'});
                    this.$store.dispatch('form/submit', false)
                    return
                }

                this.docu_implementation.documentation = this.selectedDocumentation;
                this.docu_implementation.asigned_id = this.selectedAsignedEmployee.employee_id;

                 //this.docu_implementation.url_file = this.selectedAsignedEmployee.employee_id;

                if(this.selectedDocumentation !== null){
                    if(this.inf_contract === true){
                        this.docu_implementation.expiration = moment('2100-01-01').format('DD/MM/YYYY');
                    }
                 }
                 

                DocumentationService.newDocumentationImplementation(this.docu_implementation)
                    .then(response => {
                        var isError =  false;
                        if(response.data.status === 'ERROR'){
                            isError = true;
                        }else{
                            this.$store.dispatch('employee/set-employee', this.selectedAsignedEmployee);
                            //this.$router.push({name: 'admin-documentation'});
                             this.$router.push({name: 'admin-documentation', params: { docu_id: 0 } });
                        }
                        this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
                        this.$store.dispatch('form/submit', false)
                    })
                    .catch(err => {
                      console.log(err);
                      this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.computed = {
            getEmployee(){
                return this.filteredEmployee;
            },
            ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states'
            }),
        };
        this.watch = {
            
        };
        this.props = [];
    }).apply(NewDocumentation);

    export default NewDocumentation
</script>
