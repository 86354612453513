<template id="calendarActivity">
    <div >
        
        <h4 class="text-black">Reporte Tipo Documentos</h4>
        <div class="card ml-3 mr-3 p-grid p-nogutter p-d-flex p-justify-center p-ai-cente">

                <div class="field">
                    <label for="contractdropdown" class="p-sr-only">Contrato</label>
                    <div class="field">
                        <Dropdown id="contractdropdown" v-model="selectedContract" :options="contracts" optionLabel="Name" :filter="false" placeholder="Todos los Contratos" :showClear="true">
                          <template #value="slotProps">
                              <div class="p-dropdown-supervisors-value" v-if="slotProps.value">
                                  <span>
                                    {{slotProps.value.name}}
                                  </span>
                              </div>
                              <span v-else>
                                  {{slotProps.placeholder}}
                              </span>
                          </template>
                          <template #option="slotProps">
                          <div class="p-dropdown-supervisors-option">
                            <span>
                                  {{slotProps.option.name}}</span>
                          </div>
                        </template>
                        </Dropdown>
                        <buttonp class="w-8rem p-button-warning ml-2 " icon="pi pi-search" label="BUSCAR" @click.prevent="getAllReport()"/>
                    </div>
                </div>
 
        </div>
        
        <div v-if="resultReport.length > 0" >
            <div class="card ml-6 mr-6 mt-3">
                <h4 class="text-black">TOTALES</h4>
                <h5 class="text-black">{{resultReport.length}} Tipos Documentos evaluados.</h5>
                <!--buttonp class="w-8rem p-button-warning mb-2" icon="pi pi-file-excel" label="Exportar Excel" @click.prevent="exportExcel()"/-->
            </div>
            <DataTable id="tableDocumentatio" :value="resultReport" responsiveLayout="scroll">
                <Column field="name" header="Tipo Documento" :sortable="true"></Column>
                <Column header="Colaborador Evaluados" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users.length" @click="openDetailColaborators(slotProps.data.name, slotProps.data.users)" autofocus disabled/>
                    </template>
                </Column>

                <Column field="correct" header="#CORRECTAS" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_correct.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_correct)" autofocus />
                    </template>
                </Column>
                <Column field="alert" header="#ALERTA" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_alert.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_alert)" autofocus />
                    </template>
                </Column>
                <Column field="process" header="#PROCESO" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_process.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_process)" autofocus />
                    </template>
                </Column>
                <Column field="critic" header="#CRITICOS" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_critic.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_critic)" autofocus />
                    </template>
                </Column>
                <Column field="expired" header="#EXPIRADOS" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_expired.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_expired)" autofocus />
                    </template>
                </Column>
                <Column field="check" header="#POR REVISAR" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_check.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_check)" autofocus />
                    </template>
                </Column>
                <Column field="rejected" header="#RECHAZADOS" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_rejected.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_rejected)" autofocus />
                    </template>
                </Column>
                <Column header="#RENOVADOS" :sortable="true">
                    <template #body="slotProps">
                        <buttonp :label="slotProps.data.users_renewed.length.toString()" @click="openDetailColaborator(slotProps.data.name, slotProps.data.users_renewed)" autofocus />
                    </template>
                </Column>
            </DataTable>
        </div>
        <div v-else>
            <h2>Sin Resultados</h2>
        </div>

        <br /><br /><br /><br /><br />
        <Dialog :header="selected_documentation + '(' + selectedDetails.length.toString() + ')'" v-model:visible="displayDetailDocs" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '80vw'}">

            <DataTable id="tableDetailCOlaborador" :value="selectedDetails" responsiveLayout="scroll">
                <Column header="Colaborador" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.employee}}
                            <!--buttonp :label="slotProps.data.employee" @click="openAdminColaborator(slotProps.data.e, slotProps.data.doc.documentation_implementation_id)" autofocus /-->
                    </template>
                </Column>
                <!--Column header="ID DOCUMENTO" :sortable="true">
                    <template #body="slotProps">
                        {{slotProps.data.doc.documentation_implementation_id}}
                </template>
                </Column-->
                <Column header="Expiracion" :sortable="true">
                    <template #body="slotProps">
                        <buttonp v-if="slotProps.data.doc.calculate_status == 'ALERT'" :label="getLocalDate(slotProps.data.doc.expiration).toString()" class="ml-1 p-button-sm p-button-warning" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO EN ALERTA'" autofocus/>

                        <buttonp v-if="slotProps.data.doc.calculate_status == 'CRITIC'"  :label="getLocalDate(slotProps.data.doc.expiration).toString()" class="ml-1 p-button-sm p-button-danger" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO CRITICO'" autofocus disabled/>

                        <buttonp v-if="slotProps.data.doc.calculate_status == 'EXPIRED'"  :label="getLocalDate(slotProps.data.doc.expiration).toString()" class="ml-1 p-button-sm p-button-danger" icon="pi pi-times" v-tooltip.left="'DOCUMENTO EXPIRADO'" autofocus/>

                        <buttonp v-if="slotProps.data.doc.calculate_status == 'OK'"  :label="getLocalDate(slotProps.data.doc.expiration).toString()" class="ml-1 p-button-sm p-button-success" icon="pi pi-check" v-tooltip.left="'DOCUMENTO NO EXPIRADO'" autofocus/>
                    </template>
                </Column>
                <Column header="ESTADO" :sortable="true">
                    <template #body="slotProps">
                        <statustag :status="slotProps.data.doc.status"></statustag>
                    </template>
                </Column>
                <!--Column field="limit_renew" header="Limite C.Renovacion" :sortable="true">
                    <template #body="slotProps">
                        {{getLocalDate(slotProps.data.doc.limit_renew)}}
                    </template>
                </Column-->
                <!--Column field="start_renew" header="Comenzo Renovacion" :sortable="true"></Column-->
                <!--Column field="renew" header="Fecha Renovacion" :sortable="true"></Column-->
            </DataTable>

            <br /><br /><br /><br /><br /><br /><br /><br />

            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-check" @click="closeDetail" autofocus />
            </template>
        </Dialog>
        
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';
    import ContractService from '@/service/ContractService';
    import Statustag from '@/components/StatusTag';
    import moment from "moment";


    let DocuTypeReport = {};
    (function () {
        this.name = 'all-colaborator-reports';

        this.created = function () {
            this.getAllContractUser();
            this.getAllDocumentation();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                contracts: [],
                selectedContract: null,
                resultReport: [],
                selectedDetails: [],
                documentations: [],
                displayDetailDocs: false,
                selected_documentation: null,
            }
        };
        this.methods = {
            openAdminColaborator(e, id){

                e.first_name1 = e.first_name1 +" "+e.first_name2 +' '+e.last_name1 + ' ' +e.last_name2 +'('+e.card_id +'-'+ e.check_digit +')';

                this.$store.dispatch('employee/set-employee', e);
                //let routeData = this.$router.resolve({name: 'admin-documentation', query: {data: "someData"}});
                //window.open(routeData.href, '_blank');
                this.$router.push({name: 'admin-documentation', 
                                    params: {
                                        docu_id: id
                                        }
                                    });
            },
            getDocumentationById(id){
               return  this.documentations.find(item => item.documentation_id === id).name;
            },
            getAllDocumentation(){
                this.$store.dispatch('form/submit', true);
               DocumentationService.allDocumentation().then((response) => {
                    this.documentations = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            exportExcel(){

            },
            openDetailDocumentation(id){
                 this.$router.push({name: 'edit-documentation',
                           params: {
                                documentation_implementation_id: id
                            }
                       });
            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY');
                  return local;
                }
            },
            openDetailColaborator(docu, details){
                this.selected_documentation = docu;
                this.displayDetailDocs = true;
                this.selectedDetails = details;
            },
            closeDetail(){
                this.displayDetailDocs = false;
            },
            getAllContractUser(){
                ContractService.getByEmployee(this.username).then((response) => {
                    this.contracts = response.data;
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAllReport() {
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}

                var contractsid = null
                if(this.selectedContract != null)
                    contractsid = this.selectedContract.contract_id
                DocumentationService.docuTypeReport(contractsid)
                    .then(response => {
                         this.resultReport = response.data;
                    if(response.data.length === 0){
                        result.isError = true;
                        result.message = 'Sin resultados';
                        this.$store.dispatch('form/result', result);
                    }

                    this.$store.dispatch('form/submit', false);
                    })
                    .catch(err => {
                      console.log(err);
                       this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.components = {Statustag};
        this.computed = {
            ...mapGetters({
                username: 'auth/user'
            }),
        };
        this.watch = {

        };
        this.props = [];
    }).apply(DocuTypeReport);

    export default DocuTypeReport
</script>
