<template id="doActivity">
    <span>
        <div class="md-min-width card m-8">
                <h2 class="text-black">Creacion Documentacion Total Vehiculo</h2>

                <h5 class="text-black">Vehiculo asignado a los documentos creados</h5>

                <div class="field" v-if="getVehicles.length > 0">   

                    <autoComplete class="w-12rem" v-model="selectedAsignedVehicle" field="plate" :suggestions="filteredVehicles" @complete="search($event)" placeholder="Vehiculo Dueño Documentos"></autoComplete>
                    <br />

                    <buttonp class="w-8rem mt-5 ml-1 p-button-warning" icon="pi pi-refresh" label="Cargar Vehiculos" @click.prevent="reloadVehicles()"/>

                    <buttonp class="w-8rem mt-5 ml-2 p-button-warning" icon="pi pi-plus" label="Nuevo Vehiculo en admin" @click.prevent="openPanelNewVehicle()"/>

                     <buttonp class="w-8rem mt-5 ml-2 p-button-warning" icon="pi pi-plus" label="Nuevo Vehiculo en app" @click.prevent="openNewVehicle()"/>

                    <h6 v-if="error.asigned_oblig" class="p-error">Seleccion vehiculo dueño de la documentacion.</h6>
                </div>   

                <buttonp class="w-10rem mt-5 p-button-warning" icon="pi pi-save" label="Crear Toda Documentacion Vehiculo" @click.prevent="createDocumentation()"/>

                <br/><br/>

                <br/><br/><br/><br/><br/><br/><br/><br/>
                
        </div>
    </span>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';
     import VehicleService from '@/service/VehicleService';

    let CreateAllDocuVehicle = {};
    (function () {
        this.name = 'craete-all-docu-person';
        // let _self = null;

        this.created = function () {
            this.getAllVehicles();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                allVehicles: [],
                selectedDocumentation: null,
                filteredVehicles: [],
                selectedAsignedVehicle: null,
                docu_implementation: {
                    documentation_id: null,
                    expiration: null,
                    limit_renew: null,
                    vehicle_asigned_id: null,
                    observation: null,
                }
            }
        };
        this.methods = {
            goTo(route) {
                this.$router.push({name: route});
            },
            reloadVehicles(){
                this.getAllVehicles();
            },
            openNewVehicle(){
                this.goTo('new-vehicle');
            },
            openPanelNewVehicle(){
                window.open(process.env.VUE_APP_URL+"/admin/core/vehicle/add/");
            },
            createDocumentation(){
                this.$store.dispatch('form/submit', true);

                if(this.selectedAsignedVehicle === null){
                    this.$store.dispatch('form/result', {isError: true, message: 'Seleccione Vehiculo'});
                    this.$store.dispatch('form/submit', false);
                    return;
                }
                DocumentationService.createAllDocumentationVehicle(this.selectedAsignedVehicle.vehicle_id).then((response) => {
                        var isError =  false;
                        if(response.data.status === 'ERROR'){
                            isError = true;
                        }else{
                            this.$store.dispatch('vehicle/set-vehicle', this.selectedAsignedVehicle);
                             this.$router.push({name: 'admin-documentation-vehicle', 
                                        params: {
                                            docu_id: 0
                                            }
                                        });
                        }
                        this.$store.dispatch('form/result', {isError: isError, message: response.data.message});
                        this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            search(event){
                var filteredResult = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.allVehicles.length; i++) {
                        if (this.allVehicles[i].plate.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim())
                        ) {
                            filteredResult.push(this.allVehicles[i])
                        }
                    }
                    this.filteredVehicles = filteredResult;
                } else {
                    return [];
                }   
            },

            getAllVehicles(){
                this.$store.dispatch('form/submit', true);
                VehicleService.getAll().then((response) => {
                    this.allVehicles = response.data;
                    this.filteredVehicles = response.data;
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            }
        };
        this.computed = {
            getVehicles(){
                return this.filteredVehicles;
            },
            ...mapGetters({
                username: 'auth/user',
                error: 'form/error-states'
            }),
        };
        this.watch = {
            
        };
        this.props = [];
    }).apply(CreateAllDocuVehicle);

    export default CreateAllDocuVehicle
</script>
