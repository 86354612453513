<template id="calendarActivity">
    <span>
        <ProgressBar v-if="cargando" mode="indeterminate" style="height: .1em; margin-top: -17px;" />
        <h4 class="text-black">GESTION POR COLABORADOR 
            <buttonp class="p-small p-button-warning ml-3" label="Agregar documento a Vehiculo" @click.prevent="goToNewDocu()"/>  
            <!--buttonp class="p-small p-button-warning ml-3" label="Crear Colaborador" @click.prevent="goToNewVehicle()"/--></h4>

        <div class="card ml-3 mr-3">
                <div class="field">
                    <label for="contractdropdown" class="p-sr-only">Vehiculo</label>
                    <div class="field">
                        <autoComplete v-model="selectedVehicle" field="plate" :suggestions="filteredVehicleMultiple" @complete="searchVehicleMultiple($event)" placeholder="Busque vehicle"></autoComplete>
                        <buttonp class="w-8rem p-button-warning ml-2" icon="pi pi-search" label="BUSCAR" @click.prevent="getReport()"/>
                    </div>
                </div>
        </div>
        
        <div v-if="vehicleDetail.length > 0" >

            <div class="card ml-6 mr-6">
                <h4 class="text-black">TOTALES <br />

                    <span v-if="selectedVehicle.plate">{{selectedVehicle.plate}}</span> 
                    <br />
                    <span v-if="is_active_vehicle">
                        <buttonp v-if="is_active_vehicle.is_active" :label="'Vehiculo Activo'" class="ml-1 p-button-sm p-button-success" icon="pi pi-exclamation-circle" v-tooltip.left="is_active_vehicle.message" autofocus/>
                        <buttonp v-else :label="'Vehicle Inactivo'" class="ml-1 p-button-sm p-button-danger" icon="pi pi-exclamation-circle" v-tooltip.left="is_active_vehicle.message" autofocus/>
                    </span>
                </h4>
                <br />
                <div v-if="vehicleDetail.length > 0" class="grid text-black">
                    <div class="col-12 md:col-6 lg:col-2"> <buttonp :label="'Alertas '+ total_report.alert" class=" p-button-sm p-button-warning" icon="pi pi-exclamation-circle" v-tooltip.left="'Total Alertas del emppleado evaluado'" autofocus/></div>

                    <div class="col-12 md:col-6 lg:col-2"><buttonp :label="'Criticos '+ total_report.critic" class=" p-button-sm p-button-danger p-button-outlined" icon="pi pi-exclamation-circle" v-tooltip.left="'Total Documentos Criticos del emppleado evaluado'" autofocus/></div>

                    <div class="col-12 md:col-6 lg:col-2"><buttonp :label="'Validos '+ total_report.valid" class=" p-button-sm p-button-success" icon="pi pi-check" v-tooltip.left="'Total Documentos Validos del emppleado evaluado.'" autofocus/></div>

                     <div class="col-12 md:col-6 lg:col-2"><buttonp :label="'Expirados '+ total_report.expired" class=" p-button-sm p-button-danger" icon="pi pi-exclamation-circle" v-tooltip.left="'Total Documentos Expirados del emppleado evaluado.'" autofocus/></div>

                    <div class="col-12 md:col-6 lg:col-2"><buttonp :label="'Revisar '+ total_report.check" class="p-button-sm p-button-warning p-button-outlined" icon="pi pi-exclamation-circle" v-tooltip.left="'Total Documentos por Revisar del emppleado evaluado.'" autofocus/></div>

                    <div  class="col-12 md:col-6 lg:col-2"><buttonp :label="'Evaluados:'+ total_report.total_eval" class="p-button-sm p-button-grey p-button-outlined" icon="pi pi-exclamation-circle" v-tooltip.left="'Total Documentos evaluados del empleado.'" autofocus/></div>
                </div>

                <h5 class="text-black">{{vehicleDetail.length}} Documentos encotrados.</h5>
            </div>
            <ConfirmDialog></ConfirmDialog>

            <TabView>
                <TabPanel :header="'TODOS('+vehicleDetail.length+')'">
                    <DataTable id="tableDetailColaborador" :value="vehicleDetail" responsiveLayout="scroll" v-model:selection="selectedDetailFromReport" selectionMode="single" stickyHeader="true">
                <!--Column header="ID" :sortable="true">
                    <template #body="slotProps">
                    <buttonp v-tooltip.right="'Editar Documento'"  :label="slotProps.data.documentation_implementation_id" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus />
                    </template>
                </Column-->
                <Column field="documentation_id" header="Tipo Documento" :sortable="true">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.documentation_id !== null">{{getDocumentationById(slotProps.data.documentation_id).name}}</span>
                    </template>
                </Column>
                <Column field="expiration" header="Expiracion" :sortable="true">
                    <template #body="slotProps">

                        <span v-if="slotProps.data.expiration != null">

                            <buttonp v-if="slotProps.data.calculate_status == 'ALERT'" :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-warning" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO EN ALERTA'" autofocus/>

                            <buttonp v-if="slotProps.data.calculate_status == 'CRITIC'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-danger p-button-outlined" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO CRITICO'" autofocus />

                            <buttonp v-if="slotProps.data.calculate_status == 'EXPIRED'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-danger" icon="pi pi-times" v-tooltip.left="'DOCUMENTO EXPIRADO'" autofocus/>
                            <buttonp v-if="slotProps.data.calculate_status == 'OK' && slotProps.data.expiration !== '2100-01-01T04:00:00Z'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-success" icon="pi pi-check" v-tooltip.left="'DOCUMENTO NO EXPIRADO'" autofocus/>

                            <buttonp v-if="slotProps.data.calculate_status == 'OK' && slotProps.data.expiration === '2100-01-01T04:00:00Z'"  :label="'Indefinido'" class="ml-1 p-button-sm p-button-success" icon="pi pi-check" v-tooltip.left="'DOCUMENTO NO EXPIRADO'" autofocus/>
                            
                            <buttonp v-if="slotProps.data.calculate_status == null"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-warning p-button-outlined" icon="pi pi-check" v-tooltip.left="'REVISAR DOCUMENTO'" autofocus/>

                        </span>
                        <p v-else class="text-red"> Sin Fecha</p>
                       
                        

                    </template>
                </Column>
                <Column field="status" header="ESTADO" :sortable="true">
                     <template #body="slotProps" >

                        <statustag :status="slotProps.data.status"></statustag>
                        
                    </template>
                </Column>
                <!--Column field="limit_renew" header="Limite C.Renovacion" :sortable="true">
                    <template #body="slotProps">
                        {{getLocalDate(slotProps.data.limit_renew)}}
                    </template>
                </Column-->
                <!--Column field="start_renew" header="Comenzo Renovacion" :sortable="true"></Column>
                <Column field="renew" header="Fecha Renovacion" :sortable="true"></Column-->
                <Column header="Acciones" style="width: 200px;">
                    <template #body="slotProps">

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-book" v-tooltip.left="'Ver Observaciones'" @click="openObservationModal(slotProps.data)" autofocus/>

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-pencil" v-tooltip.left="'Editar Documento'" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus :disabled="slotProps.data.status === 'STARTRENEW' || slotProps.data.status === 'REJECTED' || slotProps.data.status === 'REJECT' || slotProps.data.status === 'RENEW'" />

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-history" v-tooltip.left="'Iniciar Renovacion Documento'" @click="startRenewProcess(slotProps.data.documentation_implementation_id)" autofocus :disabled="slotProps.data.status === 'STARTRENEW' || slotProps.data.status === 'REJECTED' || slotProps.data.status === 'REJECT' || slotProps.data.status === 'RENEW'"/>

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-sign-out" v-tooltip.left="'Finalizar Renovacion'" @click="finishRenew(slotProps.data.documentation_implementation_id, slotProps.data.documentation_id, slotProps.data.vehicle_asigned_id)" autofocus :disabled="slotProps.data.status === 'REJECTED' || slotProps.data.status === 'REJECT' || slotProps.data.status === 'RENEW'"/>


                        <!--buttonp label="" icon="pi pi-pencil" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus /-->

                         <!--buttonp label="" class="ml-1 p-button-sm" icon="pi pi-pencil" v-tooltip.left="'Editar Documento'" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus />

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-history" v-tooltip.left="'Iniciar Renovacion Documento'" @click="startRenewProcess(slotProps.data.documentation_implementation_id)" autofocus />

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-sign-out" v-tooltip.left="'Finalizar Renovacion'" @click="finishRenew(slotProps.data.documentation_implementation_id, slotProps.data.documentation_id, slotProps.data.asigned_id)" autofocus />


                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-exclamation-circle" v-tooltip.left="'Rechazar Documento'" @click="rejectedDocument(slotProps.data.documentation_implementation_id)" autofocus /-->
                    

                        <deletedocu :id_docu_impl="slotProps.data.documentation_implementation_id" :only_confirmation="false" v-on:ok-edit="getReport"></deletedocu> 

                    </template>
                </Column>
            </DataTable>
                </TabPanel>
                <TabPanel :header="'Vehiculo('+detail_vehicle.length+')'">
                    <DataTable id="tableDetailColaboradorLaboral" :value="detail_vehicle" responsiveLayout="scroll" v-model:selection="selectedDetailFromReport" selectionMode="single" stickyHeader="true">
                <!--Column header="ID" :sortable="true">
                    <template #body="slotProps">
                    <buttonp v-tooltip.right="'Editar Documento'"  :label="slotProps.data.documentation_implementation_id" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus />
                    </template>
                </Column-->
                <Column field="documentation_id" header="Tipo Documento" :sortable="true">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.documentation_id !== null">{{getDocumentationById(slotProps.data.documentation_id).name}}</span>
                    </template>
                </Column>
                <Column field="expiration" header="Expiracion" :sortable="true">
                    <template #body="slotProps">

                        <span v-if="slotProps.data.expiration != null">

                            <buttonp v-if="slotProps.data.calculate_status == 'ALERT'" :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-warning" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO EN ALERTA'" autofocus/>

                            <buttonp v-if="slotProps.data.calculate_status == 'CRITIC'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-danger p-button-outlined" icon="pi pi-exclamation-circle" v-tooltip.left="'DOCUMENTO CRITICO'" autofocus />

                            <buttonp v-if="slotProps.data.calculate_status == 'EXPIRED'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-danger" icon="pi pi-times" v-tooltip.left="'DOCUMENTO EXPIRADO'" autofocus/>
                            <buttonp v-if="slotProps.data.calculate_status == 'OK' && slotProps.data.expiration !== '2100-01-01T04:00:00Z'"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-success" icon="pi pi-check" v-tooltip.left="'DOCUMENTO NO EXPIRADO'" autofocus/>

                            <buttonp v-if="slotProps.data.calculate_status == 'OK' && slotProps.data.expiration === '2100-01-01T04:00:00Z'"  :label="'Indefinido'" class="ml-1 p-button-sm p-button-success" icon="pi pi-check" v-tooltip.left="'DOCUMENTO NO EXPIRADO'" autofocus/>
                            
                            <buttonp v-if="slotProps.data.calculate_status == null"  :label="getLocalDate(slotProps.data.expiration).toString()" class="ml-1 p-button-sm p-button-warning p-button-outlined" icon="pi pi-check" v-tooltip.left="'REVISAR DOCUMENTO'" autofocus/>

                        </span>
                        <p v-else class="text-red"> Sin Fecha</p>
                       
                        

                    </template>
                </Column>
                <Column field="status" header="ESTADO" :sortable="true">
                     <template #body="slotProps" >

                        <statustag :status="slotProps.data.status"></statustag>
                        
                    </template>
                </Column>
                <!--Column field="limit_renew" header="Limite C.Renovacion" :sortable="true">
                    <template #body="slotProps">
                        {{getLocalDate(slotProps.data.limit_renew)}}
                    </template>
                </Column-->
                <!--Column field="start_renew" header="Comenzo Renovacion" :sortable="true"></Column>
                <Column field="renew" header="Fecha Renovacion" :sortable="true"></Column-->
                <Column header="Acciones" style="width: 200px;">
                    <template #body="slotProps">


                         <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-pencil" v-tooltip.left="'Editar Documento'" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus :disabled="slotProps.data.status === 'STARTRENEW' || slotProps.data.status === 'REJECTED' || slotProps.data.status === 'REJECT' || slotProps.data.status === 'RENEW'" />

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-history" v-tooltip.left="'Iniciar Renovacion Documento'" @click="startRenewProcess(slotProps.data.documentation_implementation_id)" autofocus :disabled="slotProps.data.status === 'STARTRENEW' || slotProps.data.status === 'REJECTED' || slotProps.data.status === 'REJECT' || slotProps.data.status === 'RENEW'"/>

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-sign-out" v-tooltip.left="'Finalizar Renovacion'" @click="finishRenew(slotProps.data.documentation_implementation_id, slotProps.data.documentation_id, slotProps.data.asigned_id)" autofocus :disabled="slotProps.data.status === 'REJECTED' || slotProps.data.status === 'REJECT' || slotProps.data.status === 'RENEW'"/>


                        <!--buttonp label="" icon="pi pi-pencil" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus /-->

                         <!--buttonp label="" class="ml-1 p-button-sm" icon="pi pi-pencil" v-tooltip.left="'Editar Documento'" @click="openDetailDocumentation(slotProps.data.documentation_implementation_id)" autofocus />

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-history" v-tooltip.left="'Iniciar Renovacion Documento'" @click="startRenewProcess(slotProps.data.documentation_implementation_id)" autofocus />

                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-sign-out" v-tooltip.left="'Finalizar Renovacion'" @click="finishRenew(slotProps.data.documentation_implementation_id, slotProps.data.documentation_id, slotProps.data.asigned_id)" autofocus />


                        <buttonp label="" class="ml-1 p-button-sm" icon="pi pi-exclamation-circle" v-tooltip.left="'Rechazar Documento'" @click="rejectedDocument(slotProps.data.documentation_implementation_id)" autofocus /-->
                    

                        <deletedocu :id_docu_impl="slotProps.data.documentation_implementation_id" :only_confirmation="false" v-on:ok-edit="getReport"></deletedocu> 

                    </template>
                </Column>
            </DataTable>
                </TabPanel>
            </TabView>

            <br /><br /><br /><br /><br /><br /><br /><br /><br />
            
        </div>
        <div v-else>
            <h2>Sin Resultados</h2>
        </div>


        <Dialog :header="'Observaciones Documentation'" v-model:visible="displayObsDoc" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '30vw'}">
            <div class="p-grid p-nogutter p-d-flex p-justify-center p-ai-center" style="height: 100%; min-height: 100vh">
                <obsdocucomponent :docu="selectedObservations"></obsdocucomponent>
            </div>
            <template #footer>
                <buttonp label="Cerrar" icon="pi pi-times" @click="displayObsDoc = false;" autofocus />
            </template>
        </Dialog>
        
    </span>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';
    import VehicleService from '@/service/VehicleService';
    import Statustag from '@/components/StatusTag'
    import Deletedocu from '@/components/DeleteDocuComponent'
    import Obsdocucomponent from '@/components/Obsdocucomponent'
    import moment from "moment";


    let AdminDocumentationVehicle = {};
    (function () {
        this.name = 'admin-documentation';

        this.created = function () {
            this.cargando = true;
            this.getAllVehicle();
            this.getAllDocumentation();
            this.getAllStatus();
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                cargando: false,
                allStatus: [],
                contracts: [],
                selectedContract: null,
                vehicleDetail: [],
                total_report: [],
                selectedDetails: [],
                displayDetailDocs: false,
                selectedVehicle: null,
                filteredVehicleMultiple: [],
                all_vehicle: [],
                documentations: [],
                selectedDetailFromReport: null,
                is_active_vehicle: null,
                detail_laboral: [],
                detail_conduccion: [],
                detail_vehicle: [],
                displayObsDoc: false,
                selectedObservations: null,
            }
        };
        this.methods = {
            openObservationModal(doc){
                this.displayObsDoc = true;
                this.selectedObservations = doc;
            },
            isInGroups: function (groupName) {
                for (var i = 0; i < this.groups.length; i++) {
                        if (this.groups[i].name === groupName) {
                            return true;
                        }  
                }
                return false;
            },
            getAllStatus(){
                this.$store.dispatch('form/submit', true);
                DocumentationService.getDocuStatus()
                    .then(response => {
                         this.allStatus = response.data;
                        this.$store.dispatch('form/submit', false);
                    })
                .catch(err => {
                    console.log(err);
                    this.$store.dispatch('form/submit', false)
                });
            },
            goToNewDocu(){
                this.$router.push({name: 'new-documentation-vehicle'});
            },
            goToNewPerson(){
                this.$router.push({name: 'create-all-docu-vehicle'});
            },
            startRenewProcess(id){
                this.$router.push({name: 'start-process-renew',
                   params: {
                        documentation_implementation_id: id,
                        for: 'vehicle',
                    }
                });
            },
            finishRenew(id, doc_id, vehicle_id){
                this.$router.push({name: 'finish-renew-vehicle',
                   params: {
                        documentation_implementation_id: id,
                        documentation_id: doc_id,
                        vehicle_id: vehicle_id
                    }
               });
            },
            rejectedDocument(id){
                this.$router.push({name: 'reject-documentation',
                   params: {
                        documentation_implementation_id: id
                    }
               });
            },
            expiredDocument(){

            },
            deleteDocumentation(id) {
                this.$confirm.require({
                    message: 'Desea borrar documentacion?',
                    header: 'Eliminar Documentacion',
                    icon: 'pi pi-info-circle',
                    acceptClass: 'p-button-danger',
                    accept: () => {
                        let result = {isError: false, message: null}
                        this.$store.dispatch('form/reset');
                        this.$store.dispatch('form/submit', true);
                        DocumentationService.delete(id).then((response) => {
                            if(response.data.status === 'OK'){
                                result.isError = false;
                                result.message = response.data.message;
                                this.$store.dispatch('form/result', result);
                                this.$store.dispatch('form/submit', false);
                                this.getReport();
                            } 
                        }).catch((error) => {
                            console.log(error);
                            this.$store.dispatch('form/submit', false);
                        });
                    },
                    reject: () => {
                        
                    }
                });
            },
            getDocumentationById(id){
               return  this.documentations.find(item => item.documentation_id === id);
            },
            getAllDocumentation(){
                this.$store.dispatch('form/submit', true);


               DocumentationService.allDocumentationVehicle().then((response) => {
                    this.documentations = response.data;


                    if(this.searchvehicle === '[object Object]'){

                        this.searchvehicle = null;
                        this.$store.dispatch('vehicle/set-vehicle', null);
                    }

                    if(this.searchvehicle != null || this.searchvehicle != 'null'){

                        this.selectedVehicle = this.searchvehicle;
                        this.getReport();
                    }
                    
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            isActiveVehicle(id){
                this.$store.dispatch('form/submit', true);
                let result = {isError: false, message: null}
               DocumentationService.isActiveVehicle(id).then((response) => {
                    this.is_active_vehicle = response.data;
                    if(response.data.status === 'ERROR'){
                        result.isError = true;
                        result.message = response.data.message;
                        this.$store.dispatch('form/result', result);
                    }
                    this.$store.dispatch('form/submit', false);
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getAllVehicle(){
                 VehicleService.getAll().then((response) => {
                    this.all_vehicle= response.data;
                     this.$store.dispatch('form/submit', false);
                    }).catch((error) => {
                        console.log(error);
                        this.$store.dispatch('form/submit', false);
                    });
            },
             searchVehicleMultiple(event){
                var filteredResultVehicle = [];
                if (event.query !== null && event.query.trim() !== '' && event.query.length > 0) {
                    event.query = event.query.toLowerCase();
                    for (var i = 0; i < this.all_vehicle.length; i++) {
                        if (this.all_vehicle[i].plate.toLowerCase().replace(/\s/g, '').trim().includes(event.query.replace(/\s/g, '').trim()) 
                        ) {
                            filteredResultVehicle.push(this.all_vehicle[i])
                        }
                    }
                    this.filteredVehicleMultiple = filteredResultVehicle;
                } else {
                    return [];
                }   
            },
            openDetailDocumentation(id){
                this.$router.push({name: 'edit-documentation-vehicle',
                           params: {
                                documentation_implementation_id: id
                            }
                       });
            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY');
                  return local;
                }
            },
            getReport() {
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}

                if(this.selectedVehicle == null){
                    result.isError = true;
                    result.message = 'Seleccione Vehiculo';
                    this.$store.dispatch('form/result', result);
                    this.$store.dispatch('form/submit', false)
                    return;
                }

                this.$store.dispatch('vehicle/set-vehicle', this.selectedVehicle);
                this.vehicleDetail = [];
                this.detail_vehicle =  [];
                this.total_report = [];
                this.isActiveVehicle(this.selectedVehicle.vehicle_id);
                DocumentationService.vehicleReport(this.selectedVehicle.vehicle_id)
                    .then(response => {
                         this.vehicleDetail = response.data.detail;
                         this.total_report = response.data.totals;
                          
                    if(response.data.detail.length === 0){
                        result.isError = true;
                        result.message = 'Sin resultados';
                        this.$store.dispatch('form/result', result);
                    }else{
                        for (var i = 0; i < this.vehicleDetail.length; i++) {
                               if(this.getDocumentationById(this.vehicleDetail[i].documentation_id).documentation_type.name === 'Vehiculo') {
                                    this.detail_vehicle.push(this.vehicleDetail[i]);
                               }
                        }

                        if(this.$route.params.docu_id && this.$route.params.docu_id !== 0){
                            this.selectedDetailFromReport = this.vehicleDetail.find(item => item.documentation_implementation_id === parseInt(this.$route.params.docu_id))
                        }
                    }
                    this.$store.dispatch('form/submit', false);
                    this.cargando = false;
                    })
                    .catch(err => {
                      console.log(err);
                      this.cargando = false;
                       this.$store.dispatch('form/submit', false)
                    });
                    
            },
        };
        this.components = {Statustag, Deletedocu, Obsdocucomponent};
        this.computed = {
            ...mapGetters({
                username: 'auth/user',
                searchvehicle: 'vehicle/vehicle',
                groups: 'auth/groups'
            }),
        };
        this.watch = {

        };
        this.props = [];
    }).apply(AdminDocumentationVehicle);

    export default AdminDocumentationVehicle
</script>
