<template id="calendarActivity">
    <div >
        <h4 class="text-black">Actividad de Usuarios</h4>
        
        <div v-if="logsDetail.length > 0" >

            <div class="card ml-6 mr-6">
                <h4 class="text-black">TOTALES</h4>
                <h5 class="text-black">{{logsDetail.length}} Acciones de usuario encontrados.</h5>
            </div>

            <DataTable id="tableDetailLog" :value="logsDetail" responsiveLayout="scroll">
                 <!--Column field="documentation_log_id" header="LOG ID" :sortable="true">
                </Column-->
                <Column field="documentation_implementation_id" header="ID Documento" :sortable="true">
                </Column>
                <Column field="action_date" header="Fecha Accion" :sortable="true">
                    <template #body="slotProps">
                        {{getLocalDate(slotProps.data.action_date)}}
                    </template>
                </Column>
                <Column field="action_user_id" header="Usuario ID" :sortable="true"></Column>
                <Column field="action_user_name" header="Usuario" :sortable="true"></Column>
                <Column header="Tipo Documento" :sortable="true">
                    <template #body="slotProps">
                        <span v-if="slotProps.data.documentation_id != null">{{getDocumentationById(slotProps.data.documentation_id)}}</span>
                    </template>
                </Column>
                <!--Column field="start_status" header="Estado Comienzo" :sortable="true"></Column-->
                <Column field="finish_status" header="Estado Final" :sortable="true"></Column>
                <Column field="description" header="Descripcion" :sortable="true"></Column>
            </DataTable>

        </div>
        <div v-else>
            <h2>Sin Resultados</h2>
        </div>
    </div>
</template>


<script>
    import {mapGetters} from 'vuex';
    import DocumentationService from '@/service/DocumentationService';
    //import EmployeeService from '@/service/EmployeeService';
    import moment from "moment";


    let LogDocumentation = {};
    (function () {
        this.name = 'log-documentation';

        this.created = function () {
            this.getAllDocumentation();
            
        };

        this.mounted  = function () {

        };

        this.data = function () {
            return {
                logsDetail: [],
                documentations:[],
            }
        };
        this.methods = {
            getDocumentationById(id){
               if(id)
                    return  this.documentations.find(item => item.documentation_id === parseInt(id)).name;
                else
                    return "";
            },
            getAllDocumentation(){
                this.$store.dispatch('form/submit', true);
               DocumentationService.allDocumentation().then((response) => {
                    this.documentations = response.data;
                    this.$store.dispatch('form/submit', false);
                    this.getLog();
                }).catch((error) => {
                    console.log(error);
                    this.$store.dispatch('form/submit', false);
                });
            },
            getLocalDate(date){
                if(date){
                  var stillUtc = moment.utc(date).toDate();
                  var local = moment(stillUtc).local().format('DD-MM-YYYY');
                  return local;
                }
            },
            getLog() {
                this.$store.dispatch('form/submit', true)
                let result = {isError: false, message: null}
                DocumentationService.allLogs()
                    .then(response => {
                         this.logsDetail = response.data;
                    if(response.data.length === 0){
                        result.isError = true;
                        result.message = 'No tiene historial registrados';
                        this.$store.dispatch('form/result', result);
                    }

                    this.$store.dispatch('form/submit', false);
                    })
                    .catch(err => {
                      console.log(err);
                       this.$store.dispatch('form/submit', false)
                    });
            },
        };
        this.computed = {
            ...mapGetters({
                username: 'auth/user'
            }),
        };
        this.watch = {

        };
        this.props = [];
    }).apply(LogDocumentation);

    export default LogDocumentation
</script>
