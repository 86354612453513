<template id="new_vehicle">
  <div class="fluid">
    <h2 class="text-black">NUEVO VEHICULO</h2>

    <div class="field">
      <label for="brand">Marca *</label>
      <input type="text" id="brand" v-model="brand" class="inputtext component" />
    </div>
    <div class="field">
      <label for="identifier">Identificador</label>
      <input type="text" id="identifier" v-model="identifier" class="inputtext component" />
    </div>
    <div class="field">
      <label for="vehicle_model">Modelo *</label>
      <input type="text" id="vehicle_model" v-model="vehicle_model" class="inputtext component" />
    </div>
    <div class="field">
      <label for="vehicle_type">Tipo de vehículo *</label>
      <input type="text" id="vehicle_type" v-model="vehicle_type" class="inputtext component" />
    </div>
    <div class="field">
      <label for="sub_type">Subtipo de vehículo</label>
      <input type="text" id="sub_type" v-model="sub_type" class="inputtext component" />
    </div>
    <div class="field">
      <label for="plate">Placa *</label>
      <input type="text" id="plate" v-model="plate" class="inputtext component" />
    </div>
    <div class="field">
      <label for="kilometers">Kilometraje *</label>
      <input type="number" id="kilometers" v-model="kilometers" class="inputnumber component" />
    </div>
    <div class="field">
      <label for="last_medition_km_date">Fecha última medición *</label>
      <input type="date" id="last_medition_km_date" v-model="last_medition_km_date" class="inputdate component" />
    </div>
    <div class="field">
      <label for="weekly_average_km">Promedio semanal *</label>
      <input type="number" id="weekly_average_km" v-model="weekly_average_km" class="inputnumber component" />
    </div>
    <div class="field">
      <label for="chassis_number">Número de chasis *</label>
      <input type="text" id="chassis_number" v-model="chassis_number" class="inputtext component" />
    </div>
    <div class="field">
      <label for="circulation_permit">Permiso de circulación *</label>
      <input type="date" id="circulation_permit" v-model="circulation_permit" class="inputdate component" />
    </div>
    <div class="field">
      <label for="motor_number">Número de motor</label>
      <input type="text" id="motor_number" v-model="motor_number" class="inputtext component" />
    </div>
    <div class="field">
      <label for="soap">SOAP:</label>
      <input type="date" id="soap" v-model="soap" class="inputdate component" />
    </div>
    <div class="field">
      <label for="technical_review">Revisión técnica</label>
      <input type="date" id="technical_review" v-model="technical_review" class="inputdate component" />
    </div>

    <div class="field">
      <label for="vehicle_year">Año del vehículo *</label>
      <input type="number" id="vehicle_year" v-model="vehicle_year" class="inputnumber component" />
    </div>
    <div class="field">
      <label for="default_contract">Contrato *</label>
      <select id="default_contract" v-model="default_contract" class="inputtext component">
        <option value="">Seleccione un contrato</option>
        <option v-for="contract in contracts" :key="contract.contract_id" :value="contract.contract_id">{{ contract.name }}"</option>
      </select>
    </div>
    <div class="field">
      <label for="detail_status">Detalle del estado</label>
      <input type="text" id="detail_status" v-model="detail_status" class="inputtext component" />
    </div>
    <div class="field">
       <buttonp class="w-8rem p-button-warning  ml-2" icon="pi pi-save" :disabled="!formIsValid" label="Guardar" @click.prevent="guardarVehiculo()"/>
    </div>
    <br /><br /><br /><br />
  </div>
</template>

<script>

import ContractService from "@/service/ContractService";
import VehicleService from "@/service/VehicleService";
import { mapGetters } from 'vuex';

export default {
  name: 'NewVehicle',
  data() {
    return {
      contracts: [],
      brand: '',
      identifier: '',
      vehicle_model: '',
      vehicle_type: '',
      sub_type: '',
      plate: '',
      kilometers: '',
      last_medition_km_date: '',
      weekly_average_km: '',
      chassis_number: '',
      circulation_permit: '',
      motor_number: '',
      soap: '',
      technical_review: '',
      vehicle_year: '',
      default_contract: null,
      detail_status: '',
    }
  },
  created() {
    this.getAllContractUser();
  },
  computed: {
    ...mapGetters({
      error: 'form/error-states',
      username: 'auth/user',
    }),
    formIsValid() {
      return this.brand !== '' && this.vehicle_model !== '' && this.vehicle_type !== '' && this.plate !== '' && this.kilometers !== '' && this.last_medition_km_date !== '' && this.weekly_average_km !== '' && this.chassis_number !== '' && this.circulation_permit !== '' && this.vehicle_year !== '' && this.default_contract !== null;
    }
  },
  methods: {
    getAllContractUser() {
      ContractService.getByEmployee(this.username).then((response) => {
        this.contracts = response.data;
      }).catch((error) => {
        console.log(error);
      });
    },
    guardarVehiculo() {
        
      if (this.formIsValid) {
        const vehicle = {
          brand: this.brand,
          identifier: this.identifier,
          vehicle_model: this.vehicle_model,
          vehicle_type: this.vehicle_type,
          sub_type: this.sub_type,
          plate: this.plate,
          kilometers: this.kilometers,
          last_medition_km_date: this.last_medition_km_date,
          weekly_average_km: this.weekly_average_km,
          chassis_number: this.chassis_number,
          circulation_permit: this.circulation_permit,
          motor_number: this.motor_number,
          soap: this.soap,
          technical_review: this.technical_review,
          vehicle_year: this.vehicle_year,
          default_contract: this.default_contract,
          detail_status: this.detail_status,
        };
        VehicleService.guardarVehiculo(vehicle)
          .then(() => {
            // Enviar notificación de éxito
            this.$store.dispatch('form/result', {isError: false, message: '¡Vehículo guardado exitosamente!'});
            // Limpiar formulario
            this.brand = '';
            this.identifier = '';
            this.vehicle_model = '';
            this.vehicle_type = '';
            this.sub_type = '';
            this.plate = '';
            this.kilometers = '';
            this.last_medition_km_date = '';
            this.weekly_average_km = '';
            this.chassis_number = '';
            this.circulation_permit = '';
            this.motor_number = '';
            this.soap = '';
            this.technical_review = '';
            this.vehicle_year = '';
            this.default_contract = null;
            this.detail_status = '';
          })
          .catch((error) => {
            // Enviar notificación de error
            this.$store.dispatch('form/submit', false)
            this.$store.dispatch('form/result', {isError: true, message: 'Error interno al guardar vehiculo'});

            this.$store.dispatch('form/error', 'Ha ocurrido un error al guardar el vehículo');
            console.log(error);
          });
      } else {
        // Enviar notificación de error
        this.$store.dispatch('form/submit', false)
        this.$store.dispatch('form/result', {isError: true, message: 'Por favor complete todos los campos requeridos'});
      }
    },
  },
};
</script>


